<template>
    <div style="width:95vw;">
        <div class="d-flex">
            <v-menu
                ref="menu"
                v-model="menu"
                transition="scale-transition"
                offset-y
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="queryDate.month"
                        label="月を選んでください"
                        prepend-icon="mdi-calendar"
                        readonly outlined dense
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="queryDate.month"
                    locale="ja" no-title
                    type="month"
                >
                </v-date-picker>
            </v-menu>
            <v-btn @click="getShifts" color=success class="ml-3">確定</v-btn>
        </div>
        <shift-work-area v-if="shiftData" :currentMonth=queryDate.month  :data="shiftData"/>
    </div>
</template>

<script>
import ShiftWorkArea from '@/components/Shifts/ShiftWorkArea.vue'
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek'
import {io} from "socket.io-client";
dayjs.extend(isoWeek)
export default {
    data:()=>({
        menu:false,
        queryDate:{
            month:"",
        },
        shiftData:null,
        socket:null,
    }),
    components:{
        ShiftWorkArea
    },
    watch:{
        search(){
            this.resetAll()
        }
    },
    destroyed(){
        console.log("Disconnecting")
        this.socket.on("dis", (message)=>{
            console.log("Disconnectd")
        })
    },
    methods:{
        async getShifts(){
            if(!this.queryDate.month) return
            this.socket = io(this.$hostUrl);
            this.shiftData = null
            const response = await this.$store.dispatch('get', {url:`api/getShifts?t=${this.queryDate.month}`, showUI:true})
            if(response && response.result){
                this.shiftData = response.result
            }
                
        }
    },
};

</script>

<style scoped>
.highlight{
    background: grey;
}
td{
    padding:.5em;
    border: 1px solid rgba(55, 55, 55, .5);
}
.matched {background: red;}

</style>
<template>
    <v-sheet>
        <div style="position:sticky; top:0; z-index:50" class="py-3">
            <v-btn @click="$emit('close')" class="mx-3" color="error">閉じる</v-btn>
            <v-btn @click="checkData" class="mr-3" color="warning">チェック</v-btn>
            <v-btn v-if="checkedData" :disabled="!alreadyCheck || errorCount != 0" @click="importData" color="success">チェック済みのデータをインポート</v-btn>
        </div>
        <div class="d-flex">
            <div style="color:black; z-index:9999;" id="x-spreadsheet-demo"></div>
            <h4 v-if="!checkedData" style="text-align:center; width:100%;">
                <p>データ入力したら、</p>
                <p>チェックボタンを押してください</p>                
            </h4>
            <div v-if="checkedData">
                <v-simple-table dense>
                    <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                
                            </th>
                            <th class="text-left">
                                開始
                            </th>
                            <th class="text-left">
                                終了
                            </th>
                            <th class="text-left">
                                システムメッセージ
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in checkedData" :key="index">
                            <td>{{index+1}}</td>
                            <td>{{item.start}}</td>
                            <td>{{item.end}}</td>
                            <td>{{item.msg}}</td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
            </div>
        </div>
    </v-sheet>
</template>

<script>
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
import Spreadsheet from "x-data-spreadsheet";
export default {
    props:["inputData","targetMonth"],
    data:()=>({
        s:null,
        checkMode:false,
        alreadyCheck:false,
        options:{
            mode: 'edit',
            showToolbar: false,
            showGrid: true,
            showContextmenu: false,
            view: {
                width: () => 400,
            },
            row: {
                len: 60,
                height: 25,
            },
            col: {
                len: 4,
                width: 80,
                indexWidth: 60,
                minWidth: 60,
            },
            style: {
                    align: 'left',
                    valign: 'middle',
                    textwrap: false,
                    strike: false,
                    underline: false,
                    font: {
                    name: 'Helvetica',
                    size: 10,
                },
            },
        },
        outputData:null,
        checkedData:null,
        errorCount:0
    }),
    methods:{
        parseInputData(inputData){
            let result = {}
            result.name = "シフト"
            result.rows = { 
                0:{
                    cells:{
                        0:{text:"開始日"},
                        1:{text:"開始時間"},
                        2:{text:"終了日"},
                        3:{text:"終了時間"},
                    }
                }
            }
            inputData.forEach((shift,index) => {
                let splitedStart = shift.start.split(" ")
                let splitedEnd = shift.end.split(" ")
                result.rows[index+1] = { cells: {} }
                result.rows[index+1].cells[0] = {text : splitedStart[0]}
                result.rows[index+1].cells[1] = {text : splitedStart[1]}
                result.rows[index+1].cells[2] = {text : splitedEnd[0]}
                result.rows[index+1].cells[3] = {text : splitedEnd[1]}
            })
            return result
        },
        importData(){
            this.$emit('importData', this.checkedData)
        },
        init(){
            this.s = new Spreadsheet("#x-spreadsheet-demo", this.options)
            .loadData(this.outputData ? this.outputData : {
                name:"シフト",
                rows:{
                    0:{
                        cells:{
                            0:{text:"開始日"},
                            1:{text:"開始時間"},
                            2:{text:"終了日"},
                            3:{text:"終了時間"},
                        }
                    }
                }
            }) // load data
            .change(data => {
                this.outputData = data
                this.alreadyCheck = false
            });
        
            // data validation
            this.s.validate()
        },
        checkData(){
            if(!this.outputData) return
            let result = []
            /*
            Before
                {
                    rows:{
                        <0:Number>:{
                            cells:{
                                <0:Number>:{
                                    text:<String>
                                }
                            }
                        }
                    }
                }
            After
            [
                {
                    start:<'YYYY-MM-DD HH:mm'>,
                    end:<'YYYY-MM-DD HH:mm'>,
                }
            ]
            */
            for(let row in this.outputData.rows){
                if(row != "0" && row != "len"){
                    
                    let tRow = this.outputData.rows[row]
                    if(!tRow.cells[0]||!tRow.cells[1]||!tRow.cells[2]||!tRow.cells[3]) continue
                    let tmpDate = {}
                    tmpDate.row =  Number(row)+1
                    tmpDate.start = dayjs(`${tRow.cells[0].text} ${tRow.cells[1].text}`).format('YYYY-MM-DD HH:mm')
                    tmpDate.end = dayjs(`${tRow.cells[2].text} ${tRow.cells[3].text}`).format('YYYY-MM-DD HH:mm')
                    if(
                        dayjs(tmpDate.start).format('YYYY-MM-DD HH:mm') === "Invalid Date"
                    ) continue
                    //Bunch of other checks
         
                    //Check end and start date
                    if(dayjs(tmpDate.end).isBefore(dayjs(tmpDate.start))){
                        tmpDate.msg = `行[${tmpDate.row}] 開始日と終了日を間違っていませんか？`
                        this.errorCount ++
                    }
                    //Check startDate is same month
                    if(!dayjs(tmpDate.start).isSame(dayjs(this.targetMonth),'month') ){
                        tmpDate.msg = `行[${tmpDate.row}] は指定月のシフトではないようです。`
                        this.errorCount ++
                    }
                    //Check if overlapped
                    function isOverlaped(a, b){ //check if b is overlapped with a. a,b = {start:"YYYY-MM-DD HH:mm" , end:"YYYY-MM-DD HH:mm"}
                        if(dayjs(b.start).isSameOrAfter(a.start) && dayjs(b.start).isSameOrBefore(a.end)) return true
                        if(dayjs(b.start).isSameOrBefore(a.start) && dayjs(b.end).isSameOrAfter(a.start)) return true
                        return false
                    } 
                    result.forEach(date => {
                        if(isOverlaped(date, tmpDate)){
                            tmpDate.msg = `行[${tmpDate.row}] は行[${date.row}]の時間帯と重なっています`
                            this.errorCount ++
                        }
                    });
                    
                    console.log(this.targetMonth)
                    result.push(tmpDate)
                }
            }
            this.checkedData = result
            this.checkMode = true
            this.alreadyCheck = true
        }
    },
    mounted(){
        if(this.inputData)
        this.outputData = this.parseInputData(this.inputData)
        setTimeout(()=>{
            this.init()
        },500)
    }
}
</script>

<template>
    <div>
        <v-chip label cyan>
            自動保存<span v-if="awaitingSync">({{saveCountDown}})</span>
            <v-progress-circular
                v-if="awaitingSync"
                indeterminate
                :size="20"
                color="cyan"
            ></v-progress-circular>
            <v-icon v-else color=success>mdi-check</v-icon>
        </v-chip>
        <div class="d-flex">
            <div style="width:40vw;" class="mr-3">
                <div class="d-flex my-3 justify-center">シフト一覧</div>
                <v-text-field
                    v-model="search"
                    label="検索"
                    flat
                    hide-details
                    clearable
                    clear-icon="mdi-close-circle-outline"
                    outlined dense
                ></v-text-field>
                <v-treeview 
                    v-if="userShiftData"
                    :open="openedItem"
                    :search="search"
                    rounded dense hoverable activatable
                    :returnObject="true"
                    @update:active="initItemMenu"
                    style="height: 74vh; overflow:scroll; cursor:pointer;"
                    :items="userShiftData"
                >
                    <template v-slot:prepend="{ item }">
                        <v-icon>
                            {{
                                item.type === 'team' ? 
                                'mdi-account-box-multiple':
                                item.type === 'user' ? 
                                'mdi-account': 
                                'mdi-clock'                       
                            }}
                        </v-icon>
                    </template>
                </v-treeview>
            </div>
            <div style="width: 100%">
                <div>
                    <div v-if="value">
                        {{ value.split("-")[0] + "年" + value.split("-")[1] + "月" }}
                    </div>
                </div>
                <v-calendar
                    ref="calendar"
                    style="height: 74vh"
                    v-model="value"
                    locale="ja"
                    color="pink"
                    :weekdays="weekday"
                    :type="type"
                    :events="events"
                    @click:day="showEventList"
                    @click:date="showEventList"
                    event-more-text="他{0}件"
                ></v-calendar>
            </div>
            <!---input dialog--->
            <v-dialog v-model="initMenuDisplay" v-if="selectedItem" width="607px">
                <v-card>
                    <v-card-title>{{selectedItem.name}}</v-card-title>
                    <v-card-subtitle>
                        <template v-if="selectedItem.type === 'team'">
                            <div>
                            ここで所属全員分のシフトを一括編集することができます。
                            <span class="error--text">すでに設定されているシフトが上書きされますので、ご注意ください。</span>
                            </div>
                        </template>
                        <template v-if="selectedItem.type === 'user'">
                            <div>ここで個人に対してシフトの編成ができます。</div>
                        </template>
                    </v-card-subtitle>
                    <v-card-subtitle>
                        <v-chip label color=primary @click="showTable = true">
                            <v-icon>mdi-grid</v-icon>
                            <div>テーブルで編集</div>
                        </v-chip>
                        <v-chip v-if="selectedItem.type === 'user'" label class="ml-3" @click="copied = currentSelectedShiftData; initMenuDisplay = false;">
                            <v-icon>mdi-content-copy</v-icon>
                            <div>内容を複製</div>
                        </v-chip>
                        <v-chip label @click="currentSelectedShiftData = copied" class="ml-3">
                            <v-icon>mdi-content-paste</v-icon>
                            <div>ペースト</div>
                        </v-chip>
                        <v-chip label color=success @click="overwriteShifts(selectedItem)" class="ml-3">
                            <v-icon>mdi-check</v-icon>
                            <div>確定</div>
                        </v-chip>
                        <v-chip label color=error @click="initMenuDisplay = false" class="ml-3">
                            <v-icon>mdi-cancel</v-icon>
                            <div>キャンセル</div>
                        </v-chip>
                    </v-card-subtitle>
                    <v-card-text>
                        <v-form v-for="(input, index) in currentSelectedShiftData" :key="index">
                            <div class="d-flex align-center">
                                <div class="mr-3">{{index+1}}</div>
                                <v-chip label>
                                    {{input.start}} ~ {{input.end}}
                                </v-chip>
                                <v-btn color="error" icon @click="removeInputItem(index)">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </div>
                            <div class="mb-3"></div>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <!----Table input---->
            <v-dialog persistent v-if="showTable" v-model="showTable" max-width="80vw">
                <v-sheet>
                    <working-table
                        :inputData="currentSelectedShiftData" 
                        @close="showTable=false" 
                        @importData="importDataFromTable"
                        :targetMonth="value"
                    />
                </v-sheet>
            </v-dialog>
        </div>
    </div>
</template>

<script>
import dayjs from "dayjs";
import WorkingTable from './WorkingTable.vue'
export default {
    components:{
        WorkingTable
    },
    props: {
        data: {
            type: Array,
        },
        currentMonth:{
            type : String
        }
    },
    data: () => ({
        //Table
        showTable:false,
        //Tree
        openedItem:[],
        search: "",
        //General
        userShiftData: null,
        //Calendar
        type: "month",
        weekday: [0, 1, 2, 3, 4, 5, 6],
        value: "",
        events: [],
        overlapedEvent:[],
        overlapedEventDisplay:false,
        //Others
        menuStartDate:false,
        menuEndDate:false,
        menuStartTime:false,
        menuEndTime:false,
        initMenuDisplay:false,
        selectedItem:null,
        currentSelectedShiftData:[
            /*
            {
                startDate:, startTime //For input use,
                endDate:, endTime // For input use
                start:<YYYY-MM-DD>, end:<YYYY-MM-DD> //final 
            }
            */
        ],
        copied:null,
        awaitingSync:false,
        saveTimer:null,
        saveCountDown:5,
        updatedData:{}
    }),
    watch:{
        initMenuDisplay(){
            if(this.initMenuDisplay === false)
                this.sel
        }
    },
    mounted() {
        this.value = dayjs(this.currentMonth).format("YYYY-MM-DD");
        this.userShiftData = this.generateTreeData(this.data);
        console.log(this.userShiftData);
    },
    methods: {
        async saveShifts(updatedData){
            let response = await this.$store.dispatch('post', {url:`api/saveShifts?t=${this.currentMonth}`, rawData:{updatedData}})
            if(response && response.result){
                this.updatedData = {}
                return true
            }
        },
        activateSyncTimer(){
            if(!this.updatedData) return
            clearInterval(this.saveTimer)
            this.awaitingSync = true
            this.saveCountDown = 5
            this.saveTimer = setInterval(async()=>{
                if(this.saveCountDown === 0){
                    clearInterval(this.saveTimer)
                    let saveStatus = await this.saveShifts(this.updatedData)
                    if(saveStatus){
                        this.awaitingSync = false
                        this.saveCountDown = 5
                    }
                }
                this.saveCountDown --
            }, 1000)
        },
        overwriteShifts(item){
            if(!this.currentSelectedShiftData) return
            this.events = []
            if(item.type === "user"){
                //update original data
                this.updatedData[this.data[item.index].uid._id] = this.data[item.index]
                this.data[item.index].shift = this.currentSelectedShiftData
            }else if(item.type === "team"){
                item.children.forEach(user => {
                    this.updatedData[this.data[user.index].uid._id] = this.data[user.index]
                    this.data[user.index].shift = this.currentSelectedShiftData
                });
            }
            //update tree display data
            this.userShiftData = this.generateTreeData(this.data);
            this.initMenuDisplay = false
            this.activateSyncTimer()
        },
        removeInputItem(index){
            this.currentSelectedShiftData.splice(index,1)
        },
        importDataFromTable(e){
            //Remove last input (Form)
            this.currentSelectedShiftData = e
            this.showTable = false
        },       
        initItemMenu(e){
            if(!e || e.length === 0) return
            if(e[0].type === "single") return
            this.currentSelectedShiftData = []
            this.selectedItem = e[0]
            if(this.selectedItem.type === 'user')
                this.currentSelectedShiftData = this.data[this.selectedItem.index].shift
            this.initMenuDisplay = true
        },
        generateTreeData(data) {
            let result = []
            let teamMap = {}
            /*
            {
                id: 1,
                name: 'Applications :',
                children: [
                    { id: 2, name: 'Calendar : app' },
                    { id: 3, name: 'Chrome : app' },
                    { id: 4, name: 'Webstorm : app' },
                ],
            },
            {
                name: this.names[this.rnd(0, this.names.length - 1)],
                start: first,
                end: second,
                color: this.colors[this.rnd(0, this.colors.length - 1)],
                timed: !allDay,
            }
            */
            data.forEach((user, index) => {
                let tmp = {}
                tmp.id = user.uid._id
                tmp.name = `${user.uid.name}`
                tmp.type = "user"
                tmp.index = index
                tmp.children= []
                if(user.shift.length > 0){
                    user.shift.forEach(shift => {
                        let tmpShiftDay = {}
                        let startArr = shift.start.split(" ")
                        let endArr = shift.end.split(" ")
                        tmpShiftDay.id = `${tmp.id}${shift.start}`
                        tmpShiftDay.name = `[${startArr[0]}] ${startArr[1]}-${endArr[1]}`
                        tmpShiftDay.type = "single"
                        tmp.children.push(tmpShiftDay)
                        //Push event
                        this.pushEvents(this.events, {name:`${user.uid.name}`, start:startArr[0], end:endArr[0]})
                    });
                }
                if(teamMap[user.uid.userTeam.teamName]){
                    teamMap[user.uid.userTeam.teamName].push(tmp)
                }else{
                    teamMap[user.uid.userTeam.teamName] = []
                    teamMap[user.uid.userTeam.teamName].push(tmp)
                }
            });

            //Convert teamMap to array
            for(let team in teamMap){
                result.push({
                    id:team,
                    name:team,
                    type:"team",
                    children:teamMap[team]
                })
            }
            return result
        },
        pushEvents(to, event){
            to.push({
                name:event.name,
                start: event.start,
                end: event.end,
            })
        },
        showEventList(e) {
            this.overlapedEvent = []
            const eventData = dayjs(e.date)
            for(let i = 0 ; i<this.events.length ; i++){
                if(
                    eventData.isBetween(this.events[i].start, this.events[i].end, null , '[]') 
                ){
                    this.overlapedEvent.push(this.events[i])
                }
            }
            console.log(this.overlapedEvent)
            this.overlapedEventDisplay = true
        },
    },
};
</script>
